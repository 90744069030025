import React, { useState } from 'react';
import { Box, Text, IconButton, Flex, useColorMode } from '@chakra-ui/react';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';

interface ControlElementProps {
  title?: string;
  children: React.ReactNode;
}

const ControlElement: React.FC<ControlElementProps> = ({ title, children }) => {
  const [isMinimized, setIsMinimized] = useState(false);

  const toggleMinimize = () => setIsMinimized(!isMinimized);

  const { colorMode } = useColorMode();

  return (
    <Box
      border="1px" borderColor="gray.200" borderRadius="md"
      p={3}
      bg={colorMode + ".controlElement"}
    >
      {title ?
        <>
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize={"lg"} fontWeight="bold" mb={2}>{title}</Text>
            <IconButton
              aria-label="Toggle Minimize"
              icon={isMinimized ? <ChevronDownIcon /> : <ChevronUpIcon />}
              size="sm"
              onClick={toggleMinimize}
            />
          </Flex>
          {/* <Divider borderColor={'black'} mt="2" mb="1"/> */}
        </>
        : null}
      {!isMinimized && (
        <Box>
          {children}
        </Box>
      )}
    </Box>
  );
};

export default ControlElement;