// UserAvatar.tsx
import React from 'react';
import { Avatar } from '@chakra-ui/react';

interface UserAvatarProps {
  name: string;
  color: string;
  size?: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ name, color, size = "xl" }) => {
  return (
    <Avatar
      size={size}
      name={name}
      bg={color}
      color="white"
    ></Avatar>
  );
};

export default UserAvatar;