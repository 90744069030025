import React, { useState, useEffect } from 'react';
import { Box, Text, Select, UnorderedList, ListItem, VStack, Divider, Heading, Flex } from "@chakra-ui/react";
import ReactMarkdown from 'react-markdown';

interface TaxonomyData {
  sheets: {
    [key: string]: any[];
  };
  sectors: string[];
}

const CompassPage: React.FC = () => {
  const [taxonomyData, setTaxonomyData] = useState<TaxonomyData>({ sheets: {}, sectors: [] });
  const [selectedSector, setSelectedSector] = useState<string>('');
  const [selectedActivity, setSelectedActivity] = useState<string>('');
  const [selectedTable, setSelectedTable] = useState<string>('');
  const [filteredActivities, setFilteredActivities] = useState<string[]>([]);
  const [activityDetails, setActivityDetails] = useState<any[]>([]);

  useEffect(() => {
    fetch('/processed_taxonomy_full.json')
      .then(response => response.json())
      .then(data => setTaxonomyData(data))
      .catch(error => console.error('Error fetching taxonomy data:', error));
  }, []);

  useEffect(() => {
    if (selectedSector && taxonomyData.sheets?.Master) {
      const activities = taxonomyData.sheets.Master
        .filter(item => item.Sector === selectedSector)
        .map(item => item.Activity);
      setFilteredActivities(activities);
    } else {
      setFilteredActivities(taxonomyData.sheets?.Master?.map(item => item.Activity) || []);
    }
  }, [selectedSector, taxonomyData.sheets]);

  useEffect(() => {
    if (selectedActivity && taxonomyData.sheets?.Master) {
      const selectedActivityDetails = taxonomyData.sheets.Master
        .find(item => item.Activity === selectedActivity);

      if (selectedActivityDetails) {
        const additionalInfo = Object.keys(selectedActivityDetails)
          .filter(key => key.startsWith('ID for') && selectedActivityDetails[key])
          .map(key => {
            const sheetName = key.replace('ID for ', '');
            const activityNumber = selectedActivityDetails[key];
            const detail = taxonomyData.sheets[sheetName].find(item => item['Activity number'] === activityNumber);
            return { table: sheetName, detail };
          });
        setActivityDetails(additionalInfo);

        if (additionalInfo.length > 0) {
          setSelectedTable(additionalInfo[0].table);
        }
      }
    }
  }, [selectedActivity, taxonomyData.sheets]);

  const handleSectorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSector(event.target.value);
    setSelectedActivity('');
    setActivityDetails([]);
    setSelectedTable('');
  };

  const handleActivityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedActivity(event.target.value);
    setActivityDetails([]);
    setSelectedTable('');
  };

  const handleTableChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTable(event.target.value);
  };

  const renderDetail = (detail: any) => {
    return (
      <VStack align="start" spacing={2} key={detail.NACE} pt={4} pb={4}>
        {detail.NACE ?
          <>
            <Heading as={"h4"} fontSize={"xl"}>NACE:</Heading>
            <Text>{detail.NACE}</Text>
          </>
          : null
        }
        {detail['Contribution type'] ?
          <>
            <Heading as={"h4"} fontSize={"xl"}>Contribution type:</Heading>
            <Text>{detail['Contribution type'] ?? "Not defined"}</Text>
          </>
          : null
        }
        <Box >
          <Heading as={"h4"} fontSize={"xl"} mt={8} pb={3}>Description:</Heading>
          <Box><ReactMarkdown>{detail.Description}</ReactMarkdown></Box>
          <Divider m={6} />
          <Heading as={"h4"} fontSize={"xl"} pt={2} pb={3}>Substantial contribution criteria:</Heading>
          <Box><Text>{detail['Substantial contribution criteria']}</Text></Box>
          {Object.keys(detail).filter(key => key.startsWith('DNSH on')).map(dnshKey => (
            <Box key={dnshKey}>
              <Divider m={6} />
              <Heading as={"h5"} pb={2} fontSize={"xl"}>{dnshKey}:</Heading>
              <Text key={dnshKey}>{detail[dnshKey]}</Text>
            </Box>
          ))}
          {detail.Footnotes && <Box>
            <Divider m={4} />
            <Heading as={"h4"} pb={2} pt={2} fontSize={"xl"}>Footnotes:</Heading>
            <Text>
              {detail.Footnotes}
            </Text>
          </Box>}
        </Box>
      </VStack>
    );
  };

  return (
    <Flex width={"80%"} margin={"auto"} flexDirection={"column"} pt="16" pb="16">
      <Box justifySelf={"start"}>
        <Text fontSize="lg">Select Sector and Activity:</Text>
        <Select
          placeholder="Select sector"
          value={selectedSector}
          onChange={handleSectorChange}
          margin={"4"}
          width={"640px"}
        >
          {taxonomyData.sectors.map(sector => (
            <option key={sector} value={sector}>{sector}</option>
          ))}
        </Select>
        <Select
          placeholder="Select activity"
          value={selectedActivity}
          onChange={handleActivityChange}
          margin={"4"}
          width={"640px"}
        >
          {filteredActivities.map(activity => (
            <option key={activity} value={activity}>{activity}</option>
          ))}
        </Select>

        <Select
          placeholder="Select climate protection target"
          value={selectedTable}
          onChange={handleTableChange}
          margin={"4"}
          width={"640px"}
        >
          {activityDetails.map(info => (
            <option key={info.table} value={info.table}>{info.table}</option>
          ))}
        </Select>
      </Box>

      {activityDetails.filter(info => info.table === selectedTable).map(info => (
        <Box key={info.table} mt="8">
          <UnorderedList styleType="none">
            <ListItem key={info.table}>
              <Heading as={"h3"} fontWeight="bold" fontSize={"3xl"}>{info.table}</Heading>
              <Divider m={4} />
              {renderDetail(info.detail)}
            </ListItem>
          </UnorderedList>
        </Box>
      ))}
    </Flex>
  );
};

export default CompassPage;
