import { Box, Button, Divider } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const DashboardPage: React.FC = () => {
  return <Box>
    Dashboard Area
    <Box>
      <Link to={'/login'}><Button>Go to Login Mockup</Button></Link>
    </Box>
    <Box>
      <Link to={'/chats'}><Button>Go to Chats</Button></Link>
    </Box>
    <Divider m={6} w={"50%"} />
  </Box>;
};

export default DashboardPage;
