import React, { useState, useEffect, useRef } from "react";
import { Box, Flex, Text, IconButton, Input, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { Conversation, useChat } from "../context/ChatsContext";
import { EditIcon, DeleteIcon, CopyIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useConfirmation } from "../context/ConfirmationContext";

type ElementProps = {
  conversation: Conversation;
  onActionComplete: () => void;
  isNewConversation?: boolean;
  onRemoveFromNew?: (id: number) => void;
}

const ChatSelectionElement: React.FC<ElementProps> = ({
  conversation,
  onActionComplete,
  isNewConversation = false,
  onRemoveFromNew
}) => {
  const { deleteConversation, duplicateConversation, renameConversation, fetchConversations } = useChat();
  const navigate = useNavigate();
  const { requestConfirmation } = useConfirmation();

  const [isRenaming, setIsRenaming] = useState(isNewConversation);
  const [newName, setNewName] = useState(conversation.name || `C${conversation.id}`);
  const [isHovered, setIsHovered] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const bgColor = useColorModeValue("light.controlElement", "dark.controlElement");
  const hoverBgColor = useColorModeValue("light.controlBackground", "dark.controlBackground");
  const textColor = useColorModeValue("light.text", "dark.text");
  const buttonColor = useColorModeValue("light.buttonMain", "dark.buttonMain");
  const buttonHoverColor = useColorModeValue("light.buttonHover", "dark.buttonHover");

  useEffect(() => {
    if (isNewConversation && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isNewConversation]);

  const handleNavigate = () => {
    if (!isRenaming) {
      if (isNewConversation && onRemoveFromNew) {
        onRemoveFromNew(conversation.id);
      }
      navigate(`/chat/${conversation.id}`);
    }
  };

  const handleRename = () => setIsRenaming(!isRenaming);

  const confirmRename = async () => {
    if (newName && newName.trim() !== "") {
      await renameConversation(conversation.id, newName);
      await fetchConversations(); // This will update all conversations, including the renamed one
      setIsRenaming(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      confirmRename();
    }
  };

  const handleDelete = () => {
    requestConfirmation({
      onConfirm: async () => {
        await deleteConversation(conversation.id);
        if (isNewConversation && onRemoveFromNew) {
          onRemoveFromNew(conversation.id);
        }
        await onActionComplete();
      },
      messageText: 'Are you sure you want to delete this conversation?',
      confirmButtonText: 'Delete',
      headerText: 'Confirm Deletion',
    });
  };

  const handleDuplicate = async () => {
    await duplicateConversation(conversation.id);
    await onActionComplete();
  };

  const iconButtonVisibility = isHovered && !isRenaming ? "visible" : "hidden";

  return (
    <Box
      bg={bgColor}
      _hover={{ bg: hoverBgColor }}
      cursor="pointer"
      borderRadius="md"
      p={1}
      pl={4}
      pr={6}
      mb={1}
      height={14}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      color={textColor}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Box flex={1} onClick={handleNavigate}>
          {isRenaming ? (
            <Flex>
              <Input
                size="sm"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                onKeyPress={handleKeyPress}
                mr={2}
                ref={inputRef}
              />
              <IconButton
                aria-label="Confirm rename"
                icon={<CheckIcon />}
                size="sm"
                onClick={confirmRename}
                mr={1}
                bg={buttonColor}
                _hover={{ bg: buttonHoverColor }}
              />
              <IconButton
                aria-label="Cancel rename"
                icon={<CloseIcon />}
                size="sm"
                onClick={handleRename}
                bg={buttonColor}
                _hover={{ bg: buttonHoverColor }}
              />
            </Flex>
          ) : (
            <Box>
              <Text
                fontWeight="bold"
                isTruncated
                fontSize={isHovered ? "md" : "xl"}
                transition="font-size 0.2s"
              >
                {conversation.name || `Conversation ${conversation.id}`}
              </Text>
              {isHovered && (
                <Text fontSize="xs" mt={1}>
                  Messages: {conversation.messageCount} | Tokens: {conversation.activeTokenCount}
                </Text>
              )}
            </Box>
          )}
        </Box>

        <Flex width={"200px"} justifyContent="space-between">
          <Text fontSize="xs" mt={1}>
            {conversation.messageCount} Nachrichten
          </Text>
          <Text fontSize="xs" mt={1}>
            {conversation.activeTokenCount} Tokens
          </Text>

        </Flex>

        <Flex visibility={iconButtonVisibility} ml={8}>
          <Tooltip label="Rename">
            <IconButton
              aria-label="Rename conversation"
              icon={<EditIcon />}
              size="md"
              variant="ghost"
              onClick={handleRename}
              mr={1}
              bg={buttonColor}
              _hover={{ bg: buttonHoverColor }}
            />
          </Tooltip>
          <Tooltip label="Delete">
            <IconButton
              aria-label="Delete conversation"
              icon={<DeleteIcon />}
              size="md"
              variant="ghost"
              onClick={handleDelete}
              mr={1}
              bg={buttonColor}
              _hover={{ bg: buttonHoverColor }}
            />
          </Tooltip>
          <Tooltip label="Duplicate">
            <IconButton
              aria-label="Duplicate conversation"
              icon={<CopyIcon />}
              size="md"
              variant="ghost"
              onClick={handleDuplicate}
              bg={buttonColor}
              _hover={{ bg: buttonHoverColor }}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ChatSelectionElement;