import React, { useEffect, useRef } from 'react';
import { Box, Text, Flex, IconButton, Tooltip, useColorMode } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import MessageComponent from '../components/MessageComponent';
import { useChat } from '../context/ChatsContext'; // Import the useChat hook
import { useLogin } from '../context/LoginContext';

import ControlBoard from '../components/controlboard/ControlBoard';
import ChatInput from '../components/ChatInput';
import { ArrowBackIcon } from '@chakra-ui/icons';


const DividerSymbol = () => (
  <Text mx={3}>|</Text>
);


type ChatPageProps = {
  staticHeight?: string
}

const ChatPage: React.FC<ChatPageProps> = () => {
  const { conversationId } = useParams<{ projectId: string; conversationId: string }>();
  const { currentConversation, fetchConversationDetails, sendMessage } = useChat(); // Use the useChat hook
  const { loginData } = useLogin(); // Use the LoginContext to get the user name
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const { colorMode } = useColorMode();

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (conversationId && currentConversation?.id !== parseInt(conversationId ?? '')) {
      fetchConversationDetails(parseInt(conversationId));
    }
  }, [conversationId, currentConversation?.id, fetchConversationDetails]);

  useEffect(scrollToBottom, [currentConversation?.messages.length]);

  const handleSendMessage = (message: string) => {
    if (currentConversation) {
      console.log('sending message: ', message, ' to conversation: ', conversationId, ' from user: ', loginData?.user);
      sendMessage(currentConversation.id, message, loginData?.user?.name ?? 'error');
    }
  };

  const MessageList = () => {

    if (!currentConversation || currentConversation.id === undefined) {
      return (
        <Box id='message-list' >
          <Text>No messages in this conversation</Text>
        </Box>
      );
    } else {
      return <Box id='message-list' >
        {
          currentConversation?.messages.map((message, index: number) => (
            <MessageComponent key={index} conv_id={currentConversation.id} message={message} />
          ))
        }
        <div ref={messagesEndRef} />
      </Box>;
    }

  };

  return (
    <Box flex="1" overflow="hidden">
      <Flex h="100%" w="100%">
        <Box id='chat-container' display="flex" flex={1} flexDirection="column" h="100%">
          <Flex id='chat-header' p={4} bg={colorMode + ".chatFrame"} align="center">
            <Flex alignItems={"center"}>

              <Tooltip
                placement='right'
                openDelay={500}
                label="Back to Chat List"
                aria-label="Back to Chat List">

                <RouterLink to="/chats">
                  <IconButton aria-label="Back"
                    bgColor={colorMode + ".buttonMain"} mr={4}
                    _hover={{ bgColor: colorMode + ".buttonHover" }}
                    icon={
                      <ArrowBackIcon fontSize={"2xl"} />
                    }
                  />
                </RouterLink>
              </Tooltip>

              <Box fontSize="xl" fontWeight="bold">
                {currentConversation?.name ?? 'No Name'}
              </Box>

              {/* <Box fontSize="sm">
                Conversation ID: {conversationId}
              </Box> */}

            </Flex>
          </Flex>
          <Box id='chat-main' flex="1" p={4} overflowY="auto" bg={colorMode + ".chatBackground"}>
            <MessageList />
          </Box>
          <Box id='chat-input'>
            <ChatInput onSendMessage={handleSendMessage} />
          </Box>
        </Box>
        <ControlBoard />
      </Flex>
    </Box>
  );
};

export default ChatPage;
