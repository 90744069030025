import React from "react";
import { Box, IconButton, Text, Center, Spinner, Flex } from "@chakra-ui/react";
import ControlElement from "./ControlElement";
import { ChatIcon, AttachmentIcon } from "@chakra-ui/icons";
import { Conversation, useChat } from "../../context/ChatsContext";

interface BotFunctionsProps {
  currentConversation: Conversation | null;
  generateAIResponse: (conv_id: number) => Promise<boolean>;
  findSources: (conv_id: number) => Promise<boolean>;
}

const BotFunctions: React.FC<BotFunctionsProps> = ({ currentConversation, generateAIResponse, findSources }) => {
  const { isGenerationBlocked, setGenerationBlocked } = useChat();

  return (
    <Box position="relative" mb={4}>
      <ControlElement>
        <Flex justifyContent={"space-around"}>
          <Box textAlign={"center"}>
            <IconButton
              aria-label="Generate Bot Response"
              icon={<ChatIcon />}
              _hover={{ bgColor: "green.400" }}
              bgColor={"green.200"}
              margin={"2"}
              isDisabled={isGenerationBlocked}
              onClick={async () => {
                if (!currentConversation || !currentConversation.id) return;
                setGenerationBlocked(true);
                await generateAIResponse(currentConversation?.id ?? 0);
                setGenerationBlocked(false);
              }}
            />
            <Text fontSize={"sm"}>Antwort generieren</Text>
          </Box>
          {/* <Box textAlign={"center"}>
            <IconButton
              aria-label="Find Relevant Sources"
              icon={<AttachmentIcon />}
              margin={"2"}
              _hover={{ bgColor: "yellow.400" }}
              bgColor={"yellow.200"}
              isDisabled={isGenerationBlocked}
              onClick={async () => {
                if (!currentConversation || !currentConversation.id) return;
                setGenerationBlocked(true);
                await findSources(currentConversation?.id ?? 0);
                setGenerationBlocked(false);
              }}
            />
            <Text fontSize={"sm"}>Quellen finden</Text>
          </Box> */}
        </Flex>
      </ControlElement>
      {isGenerationBlocked && (
        <Center
          position="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
          backgroundColor="rgba(128, 128, 128, 0.6)"
          zIndex="overlay"
        >
          <Spinner size="xl" />
        </Center>
      )}
    </Box>
  );
};

export default BotFunctions;