import React, { useState, useCallback, useContext, ReactNode } from 'react';

export type SortOption = 'unsorted' | 'name' | 'date';
export type SortDirection = 'asc' | 'desc';

interface SortingContextProps {
  sortOption: SortOption;
  setSortOption: React.Dispatch<React.SetStateAction<SortOption>>;
  sortDirection: SortDirection;
  setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>;
  showOnlyMyChats: boolean;
  setShowOnlyMyChats: React.Dispatch<React.SetStateAction<boolean>>;
}

const SortingContext = React.createContext<SortingContextProps | undefined>(undefined);

interface SortingProviderProps {
  children: ReactNode;
}

export const SortingProvider: React.FC<SortingProviderProps> = ({ children }: any) => {
  const [sortOption, setSortOption] = useState<SortOption>('unsorted');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [showOnlyMyChats, setShowOnlyMyChats] = useState(false);

  return (
    <SortingContext.Provider value={{
      sortOption, setSortOption,
      sortDirection, setSortDirection,
      showOnlyMyChats, setShowOnlyMyChats
    }}>
      {children}
    </SortingContext.Provider>
  );
};

export const useSorting = () => {
  const context = useContext(SortingContext);
  if (!context) {
    throw new Error('useSorting must be used within a SortingProvider');
  }
  return context;
};