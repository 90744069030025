import React from 'react';
import { Flex, Text, useColorMode } from '@chakra-ui/react';
import { useChat } from '../../context/ChatsContext';
import FileSelection from './FileSelection';
import ContextInfo from './ContextInfo';
import BotFunctions from './BotFunctions';


const ControlBoard = () => {
  const { currentConversation, filelist, addFileMessage, generateAIResponse, findSources } = useChat();

  const { colorMode } = useColorMode();

  const addFile = (filename: string) => {
    console.log("Adding file message for file: " + filename);
    addFileMessage(currentConversation?.id ?? 0, filename);
  };

  return (
    <Flex
      direction="column"
      bg={colorMode + ".controlBoard"}
      p={2} h="100%" w="300px"
      overflowY="auto"
    >
      <Flex bg={colorMode + ".controlHeader"} p={3} borderRadius="md" justify="center">
        <Text fontSize="xl" fontWeight="bold">
          Bot Steuerung
        </Text>
      </Flex>
      <Flex direction="column" justifyContent="space-between" flex={1} mt={4}>
        <FileSelection filelist={filelist} addFile={addFile} />

        <ContextInfo currentConversation={currentConversation} />

        <BotFunctions
          currentConversation={currentConversation}
          generateAIResponse={(convId) => generateAIResponse(convId)}
          findSources={findSources}
        />
      </Flex>
    </Flex>
  );
};

export default ControlBoard;