

const PRICE_PER_1K_INPUT = 0.01;
const PRICE_PER_1K_OUTPUT = 0.03;

/**
 * Round a number to a certain number of decimal places
 */
export function round(num = 0, dec = 2) {
  return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
}

export function estimateCostsForInput(inputTokenLength: number): number {
  const inputCost = inputTokenLength / 1000 * PRICE_PER_1K_INPUT;
  return round(inputCost, 3);
}

export function estimateCostsForOutput(outputTokenLength: number): number {
  const outputCost = outputTokenLength / 1000 * PRICE_PER_1K_OUTPUT;
  return round(outputCost, 3);
}

export function estimateCosts(inputTokenLength: number, outputTokenLength: number): number {
  const inputCost = estimateCostsForInput(inputTokenLength);
  const outputCost = estimateCostsForOutput(outputTokenLength);
  const total = round(inputCost + outputCost, 3);
  return total;
}