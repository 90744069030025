import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Flex, Box, Text, Center, Tooltip, IconButton, useColorMode } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import ReactMarkdown from 'react-markdown';
import { useChat, Message } from '../context/ChatsContext';

function convertToMarkdownNewlines(text: string) {
  return text.split('\n').map(line => line + '  ').join('\n');
}

const FileViewerPage: React.FC = () => {
  const { messageId } = useParams<{ messageId: string }>();
  const { currentConversation } = useChat();
  const [fileMessage, setFileMessage] = useState<Message | null>(null);
  const navigate = useNavigate();

  const { colorMode } = useColorMode();

  useEffect(() => {
    if (currentConversation && messageId) {
      const message = currentConversation.messages.find(m => m.id === messageId);
      if (message && message.type === 'file') {
        setFileMessage(message);
      }
    }
  }, [messageId, currentConversation]);

  const handleBack = () => {
    navigate(`/chat/${currentConversation?.id}`);
  };

  return (
    <Center>
      <Box maxWidth="80%" w="100%"
        bg={colorMode + ".background"}
        p={8} boxShadow="md">
        <Flex justify="space-between" align="center" mb={4}>
          <Tooltip placement='right' openDelay={500} label="Back to Chat List" aria-label="Back to Chat List">
            <IconButton
              aria-label="Back"
              onClick={handleBack}
              icon={<ArrowBackIcon fontSize={"2xl"} />}
            />
          </Tooltip>
          <Text fontSize="xl" fontWeight="bold">{fileMessage?.title}</Text>
          {fileMessage?.tokenCount && (
            <Text fontSize="md">Tokens: {fileMessage.tokenCount}</Text>
          )}
        </Flex>
        <Box overflowY="auto" maxHeight="80vh">
          {fileMessage ? (
            <ReactMarkdown className={"md-message"}>
              {convertToMarkdownNewlines(fileMessage.full_content || '')}
            </ReactMarkdown>
          ) : (
            <Text>No file found.</Text>
          )}
        </Box>
      </Box>
    </Center>
  );
};

export default FileViewerPage;
