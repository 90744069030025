import React, { useState } from 'react';
import { Box, Button, Circle, Flex, IconButton, Spacer, Text, Tooltip, useColorMode } from '@chakra-ui/react';
import { Message, useChat } from '../context/ChatsContext';
import { ArrowDownIcon, ArrowUpIcon, CopyIcon, DeleteIcon, EditIcon, MoonIcon, SunIcon, ViewIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';

const SenderImage: React.FC<{ sender: Message['sender'] }> = ({ sender }) => {
  const { colorMapping } = useChat();

  let color;
  if (colorMapping && colorMapping[sender]) {
    color = colorMapping[sender];
  } else {
    color = "gray";
  }

  return (
    <Tooltip label={sender} aria-label="sender"
      placement='right'>
      <Circle mt={1} size="30px" bg={color} />
    </Tooltip>
  );
};

const MessageHeader: React.FC<{ conv_id: number, message: Message, toggleEdit?: () => void, isHovered: boolean }> = ({ conv_id, message, toggleEdit, isHovered }) => {
  const {
    moveMessageUp,
    moveMessageDown,
    toggleMessageVisibility: toggleMessageActive,
    deleteMessage,
    translateMessage,
    isGenerationBlocked,
    setGenerationBlocked
  } = useChat();

  if (!toggleEdit) toggleEdit = () => { console.log("No toggleEdit function provided"); };

  const { id, content, timestamp, active } = message;

  const date = new Date(timestamp);

  const formattedDate = date.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });

  const formattedTime = date.toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  });

  const buttonColor = "#83c25070";
  const buttonHoverColor = "#83c250c0";

  return (
    <Flex
      position="absolute"
      top={0}
      right={0}
      p={2}
      m={1}
      alignItems="center"
      opacity={isHovered ? 1 : 0}
      transition="opacity 0.2s"
    >
      <Tooltip label={`${formattedTime} - ${formattedDate}`} aria-label="timestamp"
        placement='left'>
        <Text fontSize="xs" mr={6}>{formattedTime}</Text>
      </Tooltip>

      <Tooltip label="Translate message" openDelay={500}>
        <Button aria-label="translate"
          size="xs" ml={4}
          bgColor={buttonColor}
          opacity={isGenerationBlocked ? 0.3 : 1}
          _hover={{ bgColor: buttonHoverColor }}
          onClick={async () => {
            if (isGenerationBlocked) {
              console.log("Generation is blocked, aborting translation");
              return;
            }
            setGenerationBlocked(true);
            await translateMessage(conv_id, id);
            setGenerationBlocked(false);
          }}
          fontFamily={"geneva"}
          fontSize={"2xs"}
        >
          TR
        </Button>
      </Tooltip>
      <Tooltip label="Move message up" openDelay={500}>
        <IconButton aria-label="move up"
          size="xs" ml={4}
          bgColor={buttonColor}
          _hover={{ bgColor: buttonHoverColor }}
          icon={<ArrowUpIcon />}
          onClick={() => moveMessageUp(conv_id, id)}
        />
      </Tooltip>
      <Tooltip label="Move message down" openDelay={500}>
        <IconButton aria-label="move down"
          size="xs" ml={2}
          bgColor={buttonColor}
          _hover={{ bgColor: buttonHoverColor }}
          icon={<ArrowDownIcon />}
          onClick={() => moveMessageDown(conv_id, id)}
        />
      </Tooltip>
      <Tooltip label={active ? "Message is currently included" : "Message is currently not included"} openDelay={500}>
        <IconButton aria-label="toggle message active"
          size="xs" ml={4}
          bgColor={buttonColor}
          _hover={{ bgColor: buttonHoverColor }}
          icon={active ? <SunIcon /> : <MoonIcon />}
          opacity={"1 !important"}
          onClick={() => toggleMessageActive(conv_id, id)}
        />
      </Tooltip>
      <Tooltip label="Copy to clipboard" openDelay={500}>
        <IconButton aria-label="copy to clipboard"
          size="xs" ml={4}
          bgColor={buttonColor}
          _hover={{ bgColor: buttonHoverColor }}
          icon={<CopyIcon />}
          onClick={() => {
            navigator.clipboard.writeText(content ?? "Error while copying to clipboard");
          }}
        />
      </Tooltip>
      {
        message.type === "text" &&
        <Tooltip label="Edit message" openDelay={500}>
          <IconButton aria-label="edit"
            size="xs" ml={4}
            bgColor={buttonColor}
            _hover={{ bgColor: buttonHoverColor }}
            icon={<EditIcon />}
            onClick={() => toggleEdit!()}
          />
        </Tooltip>
      }
      <Tooltip label="Delete message" openDelay={500}>
        <IconButton aria-label="delete"
          size="xs" ml={4}
          bgColor={"#ff000070"}
          _hover={{ bgColor: "#ff0000c0" }}
          icon={<DeleteIcon />}
          onClick={() => deleteMessage(conv_id, id)}
        />
      </Tooltip>
    </Flex>
  );
};

const MessageComponent: React.FC<{ conv_id: number, message: Message }> = ({ conv_id, message }) => {
  if (message.type === "text") {
    return <TextMessageComponent conv_id={conv_id} message={message} />;
  }
  if (message.type === "file") {
    return <FileMessageComponent conv_id={conv_id} message={message} />;
  }
  else {
    return <Text>Unknown Message Type</Text>;
  }
};

const TextMessageComponent: React.FC<{ conv_id: number, message: Message }> = ({ conv_id, message }) => {
  const { id, content, active } = message;
  const [editMode, setEditMode] = React.useState(false);
  const [editContent, setEditContent] = React.useState(content);
  const [isHovered, setIsHovered] = React.useState(false);
  const { editMessage } = useChat();
  const { colorMode } = useColorMode();

  const toggleEdit = () => {
    setEditMode(!editMode);
  };

  const submitEdit = () => {
    editMessage(conv_id, id, editContent);
    toggleEdit();
  };

  const cancelEdit = () => {
    toggleEdit();
  };

  const resetEdit = () => {
    setEditContent(content);
  };

  return (
    <Flex id={`message-${id}`} py={2} w="100%"
      position="relative"
      backgroundSize='10px 10px'
      backgroundPosition='0 0, 5px 5px'
      borderRadius='3px'
      opacity={active ? 1 : 0.5}
      alignItems={"end"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box mr={2}>
        <SenderImage sender={message.sender} />
      </Box>
      <Box ml={2} mr={2} flex={1}
        bg={colorMode + ".chatMessage"}
        opacity={active ? 1 : 0.8} p={2} borderRadius="md" >
        {isHovered && <MessageHeader message={message} conv_id={conv_id} toggleEdit={toggleEdit} isHovered={isHovered} />}
        <Box mt={6}>
          {editMode ?
            <Flex direction={"column"}>
              <Box flex={1}>
                <textarea
                  value={editContent}
                  onChange={(e) => setEditContent(e.target.value)}
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
              <Spacer />
              <Flex direction={"row"} justifyContent={"flex-end"}>
                <Button colorScheme="teal" mr="2" size="xs" onClick={resetEdit}>Reset</Button>
                <Button colorScheme="teal" mr="2" size="xs" onClick={submitEdit}>Submit</Button>
                <Button colorScheme="teal" mr="2" size="xs" onClick={cancelEdit}>Cancel</Button>
              </Flex>
            </Flex>
            :
            <Box style={{ maxWidth: '100%', overflowWrap: 'break-word' }}>
              <ReactMarkdown className={"md-message"}>{content}</ReactMarkdown>
            </Box>
          }
        </Box>
      </Box>
    </Flex>
  );
};

const FileMessageComponent: React.FC<{ conv_id: number, message: Message }> = ({ conv_id, message }) => {
  const { id, content, active } = message;
  const [isHovered, setIsHovered] = React.useState(false);
  const { colorMode } = useColorMode();

  return (
    <Flex id={`message-${id}`} py={2} w="100%"
      position="relative"
      backgroundSize='10px 10px'
      backgroundPosition='0 0, 5px 5px'
      borderRadius='3px'
      opacity={active ? 1 : 0.5}
      alignItems={"end"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box mr={2}>
        <SenderImage sender={message.sender} />
      </Box>
      <Box ml={2} mr={2} flex={1}
        bg={colorMode + ".chatMessage"}
        opacity={active ? 1 : 0.8} p={2} borderRadius="md" >
        {isHovered && <MessageHeader conv_id={conv_id} message={message} isHovered={isHovered} />}
        <Box mt={6} position={"relative"}>
          <Box style={{ maxWidth: '100%', overflowWrap: 'break-word' }}>
            <ReactMarkdown className={"md-message"}>{content}</ReactMarkdown>
          </Box>
          <IconButton aria-label='view file'
            icon={<ViewIcon />} size="md" mt={2}
            position={"absolute"} top={6} right={2}
            onClick={() => { return }}
            as={RouterLink} to={`/file-viewer/${message.id}`}
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default MessageComponent;