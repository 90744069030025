import React, { useState, useEffect } from 'react';
import { Box, Text, Select, UnorderedList, ListItem, VStack, Divider, Heading, Flex } from "@chakra-ui/react";
import ReactMarkdown from 'react-markdown';

interface Activity {
  NACE: string;
  Sector: string;
  'Activity number': string;
  Activity: string;
  'Climate Protection Target': string;
  'Contribution type': string;
  Description: string;
  'Substantial contribution criteria': string;
  [key: string]: any;
}

const DVCompassPage: React.FC = () => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [sectors, setSectors] = useState<string[]>([]);
  const [selectedSector, setSelectedSector] = useState<string>('');
  const [selectedActivity, setSelectedActivity] = useState<string>('');
  const [selectedTable, setSelectedTable] = useState<string>('');
  const [filteredActivities, setFilteredActivities] = useState<string[]>([]);
  const [activityDetails, setActivityDetails] = useState<Activity[]>([]);

  // Fetch data on component load
  useEffect(() => {
    fetch('/processed_taxonomy_dv.json')
      .then(response => response.json())
      .then((data: Activity[]) => {
        setActivities(data);

        // Extract unique sectors from the activities
        const uniqueSectors = Array.from(new Set(data.map(activity => activity.Sector)));
        setSectors(uniqueSectors);
      })
      .catch(error => console.error('Error fetching taxonomy data:', error));
  }, []);

  // Update activities filtered by the selected sector
  useEffect(() => {
    if (selectedSector) {
      const activitiesInSector = activities
        .filter(activity => activity.Sector === selectedSector)
        .map(activity => activity.Activity);
      setFilteredActivities(Array.from(new Set(activitiesInSector)));
    } else {
      setFilteredActivities(Array.from(new Set(activities.map(activity => activity.Activity))));
    }
  }, [selectedSector, activities]);

  // Update details filtered by the selected activity
  useEffect(() => {
    if (selectedActivity) {
      const selectedActivityDetails = activities.filter(activity => activity.Activity === selectedActivity);
      setActivityDetails(selectedActivityDetails);

      if (selectedActivityDetails.length > 0) {
        setSelectedTable(selectedActivityDetails[0]['Climate Protection Target']);
      }
    }
  }, [selectedActivity, activities]);

  const handleSectorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSector(event.target.value);
    setSelectedActivity('');
    setActivityDetails([]);
    setSelectedTable('');
  };

  const handleActivityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedActivity(event.target.value);
    setActivityDetails([]);
    setSelectedTable('');
  };

  const handleTableChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTable(event.target.value);
  };

  // Render the details of each selected activity entry
  const renderDetail = (detail: Activity) => {
    return (
      <VStack align="start" spacing={2} key={detail.NACE} pt={4} pb={4}>
        {detail.NACE && (
          <>
            <Heading as="h4" fontSize="xl">NACE:</Heading>
            <Text>{detail.NACE}</Text>
          </>
        )}
        {detail['Contribution type'] && (
          <>
            <Heading as="h4" fontSize="xl">Beitragsart:</Heading>
            <Text>{detail['Contribution type'] ?? 'Not defined'}</Text>
          </>
        )}
        <Box>
          <Heading as="h4" fontSize="xl" mt={8} pb={3}>Beschreibung:</Heading>
          <Box><ReactMarkdown>{detail.Description}</ReactMarkdown></Box>
          <Divider m={6} />
          <Heading as="h4" fontSize="xl" pt={2} pb={3}>Wesentliche Kriterien:</Heading>
          <Box><ReactMarkdown>{detail['Substantial contribution criteria']}</ReactMarkdown></Box>
          {Object.keys(detail).filter(key => key.startsWith('DNSH on')).map(dnshKey => (
            <Box key={dnshKey}>
              <Divider m={6} />
              <Heading as="h5" pb={2} fontSize="xl">{dnshKey}:</Heading>
              <Text>{detail[dnshKey]}</Text>
            </Box>
          ))}
        </Box>
      </VStack>
    );
  };

  return (
    <Flex width="80%" margin="auto" flexDirection="column" pt="16" pb="16">
      <Box justifySelf="start">
        <Text fontSize="lg">Sektor und Aktivität auswählen:</Text>
        <Select
          placeholder="Sektor wählen"
          value={selectedSector}
          onChange={handleSectorChange}
          margin="4"
          width="640px"
        >
          {sectors.map(sector => (
            <option key={sector} value={sector}>{sector}</option>
          ))}
        </Select>
        <Select
          placeholder="Aktivität wählen"
          value={selectedActivity}
          onChange={handleActivityChange}
          margin="4"
          width="640px"
        >
          {filteredActivities.map(activity => (
            <option key={activity} value={activity}>{activity}</option>
          ))}
        </Select>

        <Select
          placeholder="Klimaschutzziel wählen"
          value={selectedTable}
          onChange={handleTableChange}
          margin="4"
          width="640px"
        >
          {Array.from(new Set(activityDetails.map(info => info['Climate Protection Target']))).map(table => (
            <option key={table} value={table}>{table}</option>
          ))}
        </Select>
      </Box>

      {activityDetails.filter(info => info['Climate Protection Target'] === selectedTable).map(info => (
        <Box key={info['Climate Protection Target']} mt="8">
          <UnorderedList styleType="none">
            <ListItem key={info['Climate Protection Target']}>
              <Heading as="h3" fontWeight="bold" fontSize="3xl">{info['Climate Protection Target']}</Heading>
              <Divider m={4} />
              {renderDetail(info)}
            </ListItem>
          </UnorderedList>
        </Box>
      ))}
    </Flex>
  );
};

export default DVCompassPage;
