import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function RouteListener() {
  const location = useLocation();

  useEffect(() => {
    console.log("Navigated to: ", location.pathname);
  }, [location]);

  return <></>;
}

export default RouteListener;