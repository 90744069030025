import React from "react";

import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

// Import the pages and the header
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import ChatPage from './pages/ChatPage';
import ConversationsListPage from './pages/ChatsListPage';

import Header from './components/Header';
import RouteListener from './components/RouteListener';
import { ProtectedRoute } from './components/ProtectedRoute';

import { LoginProvider } from './context/LoginContext';
import { ChatProvider } from './context/ChatsContext';
import { ConfirmationProvider } from './context/ConfirmationContext';
import FileViewerPage from './pages/FileViewerPage';
import CompassPage from './pages/CompassPage';
import { NotificationProvider } from './context/NotificationContext';
import DVCompassPage from "./pages/DVCompassPage";
import { SortingProvider } from "./context/SortingContext";


// Layout Component for the header and content
const Layout: React.FC = () => {
  return (
    <Box id="layout" display="flex" flex="1" flexDirection="column" h="100vh">
      <Header />
      <Outlet /> {/* Child routes */}
    </Box>
  );
};

const App: React.FC = () => {
  return (
    <NotificationProvider>
      <LoginProvider>
        <ChatProvider>
          <ConfirmationProvider>
            <SortingProvider>
              <Router>
                <RouteListener />
                <Routes>
                  <Route path="/" element={<Layout />}>
                    <Route path="/dv" element={<DVCompassPage />} />
                    <Route path="/dvcompass" element={<DVCompassPage />} />
                    <Route path="/dv-compass" element={<DVCompassPage />} />

                    <Route index element={<LoginPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route element={<ProtectedRoute />}>
                      <Route path="/dashboard" element={<DashboardPage />} />
                      <Route path="/compass" element={<CompassPage />} />
                      <Route path="/chats" element={<ConversationsListPage />} />
                      <Route path="/chat/:conversationId" element={<ChatPage />} />
                      <Route path="/file-viewer/:messageId" element={<FileViewerPage />} />
                    </Route>
                  </Route>
                </Routes>
              </Router>
            </SortingProvider>
          </ConfirmationProvider>
        </ChatProvider>
      </LoginProvider>
    </NotificationProvider>
  );
};

export default App;