import React, { useState, useCallback, useContext, ReactNode } from 'react';
import { ConfirmationModal } from '../components/ConfirmationModal'; // Import your ConfirmationModal component

interface ConfirmationContextProps {
  requestConfirmation: (options: {
    onConfirm: () => void;
    messageText: string;
    confirmButtonText: string;
    headerText: string;
  }) => void;
}

const ConfirmationContext = React.createContext<ConfirmationContextProps | undefined>(undefined);

interface ConfirmationProviderProps {
  children: ReactNode;
}

export const ConfirmationProvider: React.FC<ConfirmationProviderProps> = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmationOptions, setConfirmationOptions] = useState({
    onConfirm: () => { return },
    messageText: '',
    confirmButtonText: '',
    headerText: '',
  });

  const requestConfirmation = useCallback((options: {
    onConfirm: () => void;
    messageText: string;
    confirmButtonText: string;
    headerText: string;
  }) => {
    setConfirmationOptions(options);
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    confirmationOptions.onConfirm();
    setIsOpen(false);
  };

  return (
    <ConfirmationContext.Provider value={{ requestConfirmation }}>
      {children}
      <ConfirmationModal
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        messageText={confirmationOptions.messageText}
        confirmButtonText={confirmationOptions.confirmButtonText}
        headerText={confirmationOptions.headerText}
      />
    </ConfirmationContext.Provider>
  );
};

export const useConfirmation = () => {
  const context = useContext(ConfirmationContext);
  if (!context) {
    throw new Error('useConfirmation must be used within a ConfirmationProvider');
  }
  return context;
};