import { VStack, Textarea, Button, Box, Flex } from "@chakra-ui/react";
import React from "react";

const ChatInput: React.FC<{ onSendMessage: (message: string) => void }> = ({ onSendMessage }) => {
  const [message, setMessage] = React.useState('');

  const handleSendMessage = () => {
    if (message.trim() === '') return; // prevent sending empty messages

    onSendMessage(message); // Send message
    setMessage(''); // reset input after sending
  };

  return (
    <Box>
      <VStack align="stretch" mt={4} pr={4} pl={4} spacing={4}>
        <Textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Nachricht eintippen..."
          resize="none"
          h="4em"
          overflowY="auto"
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        />
        <Flex justifyContent="end">
          <Button onClick={handleSendMessage} mb={4}>
            Senden
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default ChatInput;