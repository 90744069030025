import React, { useEffect } from 'react';
import { Text, Heading, Input, InputGroup, InputRightElement, IconButton, Flex, Center, Button, Divider, useColorModeValue, Icon } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useLogin } from '../context/LoginContext';
import UserInfoPanel from '../components/UserInfoPanel';

const LoginPage: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const handlePasswordVisibility = () => setShowPassword(!showPassword);
  const { loginData, login, logout, updateLoginData } = useLogin();

  // Use theme colors
  const errorColor = useColorModeValue("light.error", "dark.error");

  useEffect(() => {
    console.log("Updating login data");
    updateLoginData();
  }, []);

  const handleLogin = async () => {
    const result = await login({ username, password });
    if (result) {
      setErrorMessage('');
    } else {
      setErrorMessage('Invalid username or password');
    }
  };

  const handleKeyPress = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      await handleLogin();
    }
  };

  return (
    <Center h="100%">
      <Flex direction="column" align="center" justify="center"
        w="full" maxW="md" p={4} mt={-24}>
        {loginData.isLoggedIn
          ? <UserInfoPanel loginData={loginData} logout={logout} />
          : <>
            <Heading as="h2" mb={4}>Login</Heading>
            <InputGroup size="md" mb={4}>
              <Input
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </InputGroup>
            <InputGroup size="md" mb={4}>
              <Input
                pr="4.5rem"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              <InputRightElement width="4.5rem">
                <IconButton
                  aria-label="Toggle password visibility"
                  h="1.75rem"
                  size="sm"
                  onClick={handlePasswordVisibility}
                  icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                />
              </InputRightElement>
            </InputGroup>
            <Text color={errorColor} mb={4}>{errorMessage}</Text>
            <Button size="md" onClick={handleLogin}>Login</Button>
          </>
        }
      </Flex>
    </Center>
  );
};

export default LoginPage;