import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { API_URL } from '../config';
import { useAxiosWrapper } from './NotificationContext';

console.log("Using API_URL: ", API_URL);

type User = {
  name: string;
  display: string;
  color: string;

  total_spent: number;
  // Add other fields as needed
};

type Credentials = {
  username: string;
  password: string;
};

type LoginData = {
  isLoggedIn: boolean;
  user?: User;
};

// Types for context
type LoginContextType = {
  loginData: LoginData;
  updateLoginData: () => void;
  isAuthenticated: () => boolean, // Add this line
  login: (user: Credentials) => Promise<boolean>;
  logout: () => void;
  checkAccessToken: (accessToken?: string) => Promise<boolean>;
};


// Initial login data
const initialLoginData: LoginData = {
  isLoggedIn: false,
  user: {
    name: "not logged in",
    display: "not logged in",
    color: "gray",
    total_spent: 0,
  },
};

// Create context with initial value
export const LoginContext = createContext<LoginContextType>({
  loginData: initialLoginData,
  updateLoginData: () => { return; },
  isAuthenticated: () => initialLoginData.isLoggedIn, // Add this line
  login: async () => false,
  logout: async () => { return; },
  checkAccessToken: async () => { return false; }
});


interface LoginProviderProps {
  children: ReactNode;
}

export const LoginProvider: React.FC<LoginProviderProps> = ({ children }: LoginProviderProps) => {
  const [loginData, setLoginData] = useState<LoginData>(initialLoginData);
  const axios = useAxiosWrapper();

  const checkAuthStatus = async () => {
    try {
      const response = await axios.get(API_URL + '/users/check-auth', { withCredentials: true });
      if (response.data.isLoggedIn) {
        const { user } = response.data;
        console.log("Updating login data: ", user);
        setLoginData({ isLoggedIn: true, user });
      }
    } catch (error) {
      console.error("Error checking auth status", error);
    }
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);



  const isAuthenticated = () => {
    return loginData.isLoggedIn;
  };

  const updateLoginData = checkAuthStatus;


  const login = async (cred: Credentials) => {
    try {
      console.log("cred: ", cred);
      const response = await axios.post(API_URL + '/users/login',
        { username: cred.username, password: cred.password },
        { withCredentials: true }
      );

      if (response.status === 200) {
        console.log("--- Login successful! ---");
        console.log(response);
        setLoginData({
          isLoggedIn: true,
          user: response.data.user
        });
        return true;
      } else {
        console.error("--- Login failed! ---");
        return false;
      }
    } catch (error) {
      console.error("--- Login failed! ---");
      return false;
    }
  };


  const logout = async () => {
    try {
      const response = await axios.post(API_URL + '/users/logout', {}, { withCredentials: true });
      if (response.status === 200) {
        console.log("--- Logout successful! ---");
        console.log(response);
        setLoginData(initialLoginData);
        return true;
      } else {
        console.error("--- Logout failed! ---");
        console.error(response);
        return false;
      }
    } catch (error) {
      console.error("--- Logout failed! ---");
      console.error(error);
      return false;
    }
  };


  const checkAccessToken = async (accessToken?: string) => {
    console.log("Checking access token: ", accessToken);

    const accepted_tokens = [
      "JufKcncfoxbaiXA7",
      "APrF4ydc5Trols0K",
      "dv9c7DiT0Yz3TRNm",
      "rkek2BzZJ9F9Rha1",
      "PQGZtm18dpoKdwrv"
    ]

    if (accessToken && accepted_tokens.includes(accessToken)) {
      return true;
    } else {
      return false;
    }

    // if (!accessToken) {
    //   const token = localStorage.getItem('token');
    //   const expires = localStorage.getItem('expires');

    //   if (token && expires && new Date(expires) > new Date()) {
    //     return true;
    //   } else if (token && expires && new Date(expires) < new Date()) {
    //     localStorage.removeItem('token');
    //     localStorage.removeItem('expires');
    //     return false;
    //   }

    //   return false;
    // }

    // const response = await axios.post(`${API_URL}/temp/authToken`, {
    //   token: accessToken
    // }, { withCredentials: true });

    // if (response.status === 200) {
    //   // save the token to local storage for one hour
    //   const expires = new Date(Date.now() + 1000 * 60 * 60); // one hour from now
    //   localStorage.setItem('token', accessToken);
    //   localStorage.setItem('expires', expires.toISOString());
    //   return true;
    // } else {
    //   return false;
    // }
  }

  return (
    <LoginContext.Provider value={{
      loginData,
      isAuthenticated,
      updateLoginData,
      login,
      logout,
      checkAccessToken
    }}>
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error('useLogin must be used within a LoginProvider');
  }
  return context;
};