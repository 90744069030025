/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect, ReactNode, useContext, useCallback } from 'react';
import { API_URL } from '../config';
import { LoginContext } from './LoginContext';
import { useAxiosWrapper } from './NotificationContext';

// Types for message and conversation
export type Message = {
  id: string;
  type: string;
  title: string;
  sender: string;
  content?: string;
  fileUrl?: string; // for file messages
  full_content?: string; // for file messages
  tokenCount?: number; // for file messages
  timestamp: string;
  active: boolean;
};

export type Conversation = {
  id: number;
  messages: Message[];
  name?: string;
  activeTokenCount?: number;
  totalTokenCount?: number;
  settings?: any;
  messageCount?: number;
  // display_name?: string;
  last_edited?: string;
};

interface ChatContextType {
  conversations: Conversation[];
  currentConversation: Conversation | null;
  fetchConversations: () => Promise<void>;
  fetchConversationDetails: (conv_id: number) => Promise<void>;
  createNewConversation: () => Promise<Conversation>;
  deleteConversation: (conv_id: number) => Promise<void>;
  duplicateConversation: (conv_id: number) => Promise<void>;
  renameConversation: (conv_id: number, name: string) => Promise<void>;

  sendMessage: (conv_id: number, message: string, sender: string) => Promise<void>;
  moveMessageUp: (conv_id: number, msg_id: string) => Promise<void>
  moveMessageDown: (conv_id: number, msg_id: string) => Promise<void>
  toggleMessageVisibility: (conv_id: number, msg_id: string) => Promise<void>
  editMessage: (conv_id: number, msg_id: string, editText?: string) => Promise<void>
  deleteMessage: (conv_id: number, msg_id: string) => Promise<void>;
  translateMessage: (conv_id: number, msg_id: string) => Promise<void>;

  setGenerationBlocked: (value: boolean) => void;
  isGenerationBlocked: boolean;

  filelist: string[];
  getFileList: () => Promise<void>;
  addFileMessage: (conv_id: number, filename: string) => Promise<void>;

  settingsOptions: any;
  getSettings: (conv_id: number) => Promise<any>;
  updateSettings: (conv_id: number, settings: any) => Promise<any>;
  getAllSettings: () => Promise<any>;

  generateAIResponse: (conv_id: number) => Promise<boolean>;
  findSources: (conv_id: number) => Promise<boolean>;

  colorMapping: Record<string, string> | null;
  // Define other functions for message manipulation
}

const initialContext: ChatContextType = {
  conversations: [],
  currentConversation: null,
  fetchConversations: async () => { },
  fetchConversationDetails: async () => { },
  createNewConversation: async () => {
    return {
      id: 0,
      messages: [],
      name: "Empty",
      last_edited: new Date().toISOString(),
      activeTokenCount: 0,
      totalTokenCount: 0,
      messageCount: 0
    }
  },

  deleteConversation: async (conv_id: number) => { },
  duplicateConversation: async (conv_id: number) => { },
  renameConversation: async (conv_id: number, name: string) => { },

  sendMessage: async (conv_id: number, message: string, sender: string) => { },
  moveMessageUp: async (conv_id: number, msg_id: string) => { },
  moveMessageDown: async (conv_id: number, msg_id: string) => { },
  toggleMessageVisibility: async (conv_id: number, msg_id: string) => { },
  editMessage: async (conv_id: number, msg_id: string, editText?: string) => { },
  deleteMessage: async (conv_id: number, msg_id: string) => { },
  translateMessage: async (conv_id: number, msg_id: string) => { },

  setGenerationBlocked: async (value: boolean) => { },
  isGenerationBlocked: true,

  filelist: [],
  getFileList: async () => { },
  addFileMessage: async (conv_id: number, filename: string) => { },

  settingsOptions: {},
  getSettings: async (conv_id: number) => { },
  updateSettings: async (conv_id: number, settings: any) => { },
  getAllSettings: async () => { },

  generateAIResponse: async (conv_id: number) => { return false; },
  findSources: async (conv_id: number) => { return false; },

  colorMapping: null,
  // Initialize other functions
};

export const ChatContext = createContext<ChatContextType>(initialContext);

interface ChatProviderProps {
  children: ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const { loginData } = useContext(LoginContext);

  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [currentConversation, setCurrentConversation] = useState<Conversation | null>(null);

  const [filelist, setFileList] = useState<string[]>([]);

  const [colorMapping, setColorMapping] = useState<Record<string, string>>({}); // map sender to color

  const [settingsOptions, setSettingsOptions] = useState<any>({});

  const [generationBlocked, setGenerationBlocked] = useState<boolean>(false);

  const axios = useAxiosWrapper();

  useEffect(() => {
    console.log("login data: ", loginData);
    if (loginData.isLoggedIn) {
      fetchConversations();
      getFileList();
      updateColorMapping();
      getSettingsOptions();
    }
  }, [loginData]);

  const fetchConversations = useCallback(async () => {
    try {
      const response = await axios.get(API_URL + '/conversations', { withCredentials: true });
      const fetchedConversations = await Promise.all(response.data.conversations.map(async (conv: any) => {
        const detailsResponse = await axios.get(`${API_URL}/conversations/${conv.id}`, { withCredentials: true });
        return {
          id: conv.id,
          name: conv.name,
          activeTokenCount: conv.activeTokenCount,
          totalTokenCount: conv.totalTokenCount,
          messages: detailsResponse.data.messages,
          messageCount: conv.messageCount,
          last_edited: conv.last_edited,
        };
      }));
      setConversations(fetchedConversations);
      console.log('Fetched conversations:', fetchedConversations);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  }, [axios]);

  const fetchConversationDetails = async (conv_id: number) => {
    try {
      const response = await axios.get(`${API_URL}/conversations/${conv_id}`, { withCredentials: true });
      setCurrentConversation({
        id: conv_id,
        messages: response.data.messages,
        name: response.data.name,
        activeTokenCount: response.data.activeTokenCount,
        totalTokenCount: response.data.totalTokenCount,
        settings: response.data.settings,
      });
      // console.log("set conversation: ", { 
      //   id: conv_id, 
      //   messages: response.data.messages,
      //   name: response.data.name,
      //   activeTokenCount: response.data.activeTokenCount,
      //   totalTokenCount: response.data.totalTokenCount,
      //   settings: response.data.settings,
      // });
    } catch (error) {
      console.error('Error fetching conversation details:', error);
    }
  };

  // Function to create a new conversation
  const createNewConversation = async (): Promise<Conversation> => {
    try {
      const response = await axios.post(API_URL + '/conversations', {}, { withCredentials: true });
      const newConversation: Conversation = {
        id: response.data.conv_id,
        messages: [],
        name: `C${response.data.conv_id}`,
        last_edited: new Date().toISOString(),
        activeTokenCount: 0,
        totalTokenCount: 0,
        messageCount: 0
      };
      setConversations(prevConversations => [...prevConversations, newConversation]);
      return newConversation;
    } catch (error) {
      console.error('Error creating new conversation:', error);
      throw error;
    }
  };

  // Function to delete a conversation
  const deleteConversation = async (conv_id: number) => {
    try {
      await axios.delete(`${API_URL}/conversations/${conv_id}`, { withCredentials: true });
      setConversations(conversations.filter(conv => conv.id !== conv_id));
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  // Function to duplicate a conversation
  const duplicateConversation = async (conv_id: number) => {
    try {
      const response = await axios.post(`${API_URL}/conversations/${conv_id}/duplicate`, {}, { withCredentials: true });
      const newConvId = response.data.new_conv_id;
      // Fetch details of the new conversation and add it to the state
      await fetchConversationDetails(newConvId);
    } catch (error) {
      console.error('Error duplicating conversation:', error);
    }
  };

  const renameConversation = async (conv_id: number, name: string) => {
    try {
      await axios.post(`${API_URL}/conversations/${conv_id}/rename`, { name }, { withCredentials: true });
      fetchConversationDetails(conv_id);
    } catch (error) {
      console.error('Error renaming conversation:', error);
    }
  };


  const sendMessage = async (conv_id: number, message: string, sender: string) => {
    try {
      await axios.post(`${API_URL}/messages/${conv_id}/add`, { sender, content: message }, { withCredentials: true });
      // After sending, fetch the conversation details again to update the UI
      fetchConversationDetails(conv_id);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const moveMessageUp = async (conv_id: number, msg_id: string) => {
    try {
      await axios.post(`${API_URL}/messages/${conv_id}/${msg_id}/move-up`, {}, { withCredentials: true });
      fetchConversationDetails(conv_id);
    } catch (error) {
      console.error('Error moving message up:', error);
    }
  };

  const moveMessageDown = async (conv_id: number, msg_id: string) => {
    try {
      await axios.post(`${API_URL}/messages/${conv_id}/${msg_id}/move-down`, {}, { withCredentials: true });
      fetchConversationDetails(conv_id);
    } catch (error) {
      console.error('Error moving message down:', error);
    }
  };

  const toggleMessageActive = async (conv_id: number, msg_id: string) => {
    try {
      await axios.post(`${API_URL}/messages/${conv_id}/${msg_id}/toggle-active`, {}, { withCredentials: true });
      fetchConversationDetails(conv_id);
    } catch (error) {
      console.error('Error toggling message active:', error);
    }
  };

  const editMessage = async (conv_id: number, msg_id: string, editText?: string) => {
    try {
      await axios.post(`${API_URL}/messages/${conv_id}/${msg_id}/edit`, { content: editText }, { withCredentials: true });
      fetchConversationDetails(conv_id);
    } catch (error) {
      console.error('Error editing message:', error);
    }
  };

  const deleteMessage = async (conv_id: number, msg_id: string) => {
    try {
      await axios.delete(`${API_URL}/messages/${conv_id}/${msg_id}`, { withCredentials: true });
      fetchConversationDetails(conv_id);
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const translateMessage = async (conv_id: number, msg_id: string) => {
    try {
      await axios.post(`${API_URL}/messages/${conv_id}/${msg_id}/translate`, {}, { withCredentials: true });
      fetchConversationDetails(conv_id);
    } catch (error) {
      console.error('Error translating message:', error);
    }
  };

  const getSettings = async (conv_id: number) => {
    try {
      const response = await axios.get(`${API_URL}/conversations/${conv_id}/settings`, { withCredentials: true });
      // console.log("get settings response: ", response.data);
      return response.data;
    } catch (error) {
      console.error('Error getting settings:', error);
    }
  };

  const updateSettings = async (conv_id: number, settings: any) => {
    try {
      const response = await axios.post(`${API_URL}/conversations/${conv_id}/settings`, { settings: settings }, { withCredentials: true });
      // console.log("update settings response: ", response.data.settings);
      await fetchConversationDetails(conv_id);
      return response;
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  const getSettingsOptions = async () => {
    try {
      const response = await axios.get(`${API_URL}/conversations/allsettingsoptions`, { withCredentials: true });
      // console.log("get all settings response: ", response.data.allOptions);
      setSettingsOptions(response.data.allOptions);
      return response.data;
    } catch (error) {
      console.error('Error getting all settings:', error);
    }
  };

  const getFileList = async () => {
    try {
      const response = await axios.get(`${API_URL}/files`, { withCredentials: true });
      setFileList(response.data.files);
    } catch (error) {
      console.error('Error getting file list:', error);
    }
  };

  const addFileMessage = async (conv_id: number, filename: string) => {
    try {
      await axios.post(`${API_URL}/files/add/${conv_id}`, { sender: loginData.user?.name, filename: filename }, { withCredentials: true });
      fetchConversationDetails(conv_id);
    } catch (error) {
      console.error('Error adding file message:', error);
    }
  };

  const generateAIResponse = async (conv_id: number) => {
    try {
      await axios.post(`${API_URL}/conversations/${conv_id}/generate`, {}, { withCredentials: true });
      fetchConversationDetails(conv_id);
      return true;
    } catch (error) {
      console.error('Error generating AI response:', error);
      return false;
    }
  };

  const findSources = async (conv_id: number) => {
    try {
      await axios.post(`${API_URL}/conversations/${conv_id}/addSources`, {}, { withCredentials: true });
      fetchConversationDetails(conv_id);
      return true;
    } catch (error) {
      console.error('Error finding sources:', error);
      return false;
    }
  };


  const updateColorMapping = async () => {
    try {
      // fetch user color map from backend 
      const response = await axios.get(`${API_URL}/users/get-color-mapping`, { withCredentials: true });
      setColorMapping(response.data.colorMapping);
      console.log("color mapping: ", response.data.colorMapping);
    } catch (error) {
      console.error('Error fetching color mapping:', error);
    }
  };

  // Define other functions for message manipulation here

  return (
    <ChatContext.Provider value={{
      conversations,
      currentConversation,
      fetchConversations,
      fetchConversationDetails,
      createNewConversation,
      deleteConversation,
      duplicateConversation,
      renameConversation,

      sendMessage,
      moveMessageUp,
      moveMessageDown,
      toggleMessageVisibility: toggleMessageActive,
      editMessage,
      deleteMessage,
      translateMessage,

      setGenerationBlocked,
      isGenerationBlocked: generationBlocked,

      settingsOptions,
      getSettings,
      updateSettings,
      getAllSettings: getSettingsOptions,

      filelist,
      getFileList,
      addFileMessage,

      generateAIResponse,
      findSources,

      colorMapping,
    }}>
      {children}
    </ChatContext.Provider>
  );
};

// Custom hook for using chat context
export const useChat = () => useContext(ChatContext);