// UserInfoPanel.tsx
import React from 'react';
import { Text, Heading, Flex, Button, Divider, VStack, Icon } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ChatIcon } from '@chakra-ui/icons';
import { FaCompass } from "react-icons/fa";
import { round } from '../utils/costEstimation';
import UserAvatar from './UserAvatar'; // Import the new UserAvatar component

interface UserInfoPanelProps {
  loginData: any;
  logout: () => void;
}

const UserInfoPanel: React.FC<UserInfoPanelProps> = ({ loginData, logout }) => {
  const NavButton: React.FC<{ to: string; text: string; icon: any }> = ({ to, text, icon }) => (
    <RouterLink to={to}>
      <Button m={5} w="auto" h="auto">
        <Flex direction="column" align="center" justify="center">
          <Text mt={3}>{text}</Text>
          <Icon m={4} mb={6} fontSize="4xl" as={icon} />
        </Flex>
      </Button>
    </RouterLink>
  );

  return (
    <VStack spacing={6} align="stretch" w="full">

      <Flex direction="row" align="center" justify="center">
        <NavButton to="/compass" text="EN Compass" icon={FaCompass} />
        <NavButton to="/chats" text="Chats" icon={ChatIcon} />
        <NavButton to="/dvcompass" text="DV Compass" icon={FaCompass} />
      </Flex>

      <Divider />

      <Flex direction="column" align="center">
        <UserAvatar
          name={loginData.user?.display || loginData.user?.name}
          color={loginData.user?.color || "gray.500"}
          size="xl"
        />
        <Heading as="h2" size="md" mt={4} mb={2}>Willkommen, {loginData.user?.display}!</Heading>
        <Text fontSize="sm" color="gray.500">@{loginData.user?.name}</Text>
      </Flex>

      <Divider />

      <VStack align="start" spacing={2}>
        <Text fontWeight="bold">Account Informationen</Text>
        <Text>Insgesamt ausgegeben: {round(loginData.user?.total_spent, 2)} €</Text>
      </VStack>

      <Divider />

      <Button colorScheme="red" onClick={logout}>Ausloggen</Button>
    </VStack>
  );
};

export default UserInfoPanel;