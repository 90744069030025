import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useLogin } from '../context/LoginContext';

export const ProtectedRoute = () => {
  const { loginData } = useLogin();

  if (!loginData.isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were trying to go to
    return <Navigate to="/login" replace />;
  }

  return <Outlet />; // If logged in, continue to the requested page
};