import { extendTheme, StyleFunctionProps, type ThemeConfig } from "@chakra-ui/react";
import { mode } from '@chakra-ui/theme-tools';

const config: ThemeConfig = {
  initialColorMode: "system",
  useSystemColorMode: true,
};

// Define your color palette
const colors = {
  light: {
    background: "#f0fff4",
    text: "#333333",
    primary: "#38A169",
    secondary: "#e6ffe6",
    error: "#ff5555",

    headerColor: "#7ebf48",

    buttonMain: "#a0dc6f",
    buttonHover: "#79bc43",

    chatBackground: "#c5ddd0",
    chatFrame: "#f0fff4",
    chatMessage: "#dbf6e8",

    controlBackground: "#b6f7b6",
    controlHeader: "#9bd56c",
    controlElement: "#f6fff6",

    buttonHighlightText: "#1275de"
  },

  dark: {
    background: "#0e1209",
    text: "#ffffff",
    primary: "#2F855A",
    secondary: "#1e2614",
    error: "#ff5555",

    headerColor: "#28430a",

    buttonMain: "#507b00",
    buttonHover: "#739f22",

    chatBackground: "#1e2614",
    chatFrame: "#0e1209",
    chatMessage: "#425a14",

    controlBackground: "#28430a",
    controlHeader: "#29351b",
    controlElement: "#242f16",

    buttonHighlightText: "#75aeeb"
  },

  // Custom green color scheme
  ecoGreen: {
    light: {
      50: '#eff7e0', // Lightest shade, almost white
      100: '#daf1b2', // Lighter shade of A7D930
      200: '#c5ea94', // Slightly lighter than semi-light color
      300: '#b0e376', // Slightly darker than semi-light color
      400: '#9bdb58', // Close to Yellow-Green (semi-light)
      500: '#a7d930', // Yellow-Green (light color)
      600: '#95c22b', // Yellow-Green (semi-light color)
      700: '#739f22', // Darker shade of Yellow-Green
      800: '#507b00', // Avocado (semi-dark color)
      900: '#28430a', // Lincoln Green (dark color)
    },
    dark: {
      50: '#28430a', // Lincoln Green (dark color)
      100: '#365613', // Darker shade of Avocado
      200: '#446a1c', // Avocado (semi-dark color)
      300: '#528425', // A shade between Avocado and Yellow-Green
      400: '#609e2e', // Close to Yellow-Green (semi-light)
      500: '#6eb837', // Yellow-Green (semi-light color)
      600: '#7cd240', // Lighter shade of Yellow-Green
      700: '#8aed4b', // Close to Yellow-Green (light color)
      800: '#98f755', // Slightly lighter than A7D930
      900: '#a7d930', // Yellow-Green (light color)
    }
  }
};

const styles = {
  global: (props: StyleFunctionProps) => ({
    body: {
      fontFamily: 'body',
      color: mode(colors.light.text, colors.dark.text)(props),
      bg: mode(colors.light.background, colors.dark.background)(props),
      lineHeight: 'base',
    },

  })
};

const components = {
  Button: {
    variants: {
      outline: (props: StyleFunctionProps) => ({
        border: "2px solid",
        borderColor: mode(colors.light.buttonMain, colors.dark.buttonMain)(props),
        //color: mode(colors.light.primary, colors.dark.primary)(props),
        _hover: {
          bg: mode(colors.light.buttonHover, colors.dark.buttonHover)(props),
          //color: mode(colors.light.background, colors.dark.background)(props),
        },
      }),
      solid: (props: StyleFunctionProps) => ({
        bg: mode(colors.light.buttonMain, colors.dark.buttonMain)(props),
        //color: mode(colors.light.background, colors.dark.background)(props),
        _hover: {
          bg: mode(colors.light.buttonHover, colors.dark.buttonHover)(props),
          //color: mode(colors.light.background, colors.dark.background)(props),
        },
      }),
      // link: (props: StyleFunctionProps) => ({
      //   color: mode(colors.light.primary, colors.dark.primary)(props),
      //   _hover: {
      //     color: mode(colors.light.secondary, colors.dark.secondary)(props),
      //   },
      // }),
    },
  },
  IconButton: {

    variants: {
      outline: (props: StyleFunctionProps) => ({
        border: "2px solid",
        borderColor: mode(colors.light.buttonMain, colors.dark.buttonMain)(props),
        //color: mode(colors.light.primary, colors.dark.primary)(props),
        _hover: {
          bg: mode(colors.light.buttonHover, colors.dark.buttonHover)(props),
          //color: mode(colors.light.background, colors.dark.background)(props),
        },
      }),
      solid: (props: StyleFunctionProps) => ({
        bg: mode(colors.light.buttonMain, colors.dark.buttonMain)(props),
        //color: mode(colors.light.background, colors.dark.background)(props),
        _hover: {
          bg: mode(colors.light.buttonHover, colors.dark.buttonHover)(props),
          //color: mode(colors.light.background, colors.dark.background)(props),
        },
      }),
      // link: (props: StyleFunctionProps) => ({
      //   color: mode(colors.light.primary, colors.dark.primary)(props),
      //   _hover: {
      //     color: mode(colors.light.secondary, colors.dark.secondary)(props),
      //   },
      // }),
    },
  },
};

// Extend the theme
const theme = extendTheme({
  config,
  colors,
  styles,
  components,
});


export default theme;
