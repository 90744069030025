import React from 'react';
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Circle, Flex, useColorMode, Image } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { useLogin } from '../context/LoginContext';
import UserAvatar from './UserAvatar'; // Import the UserAvatar component

import LogoImage from '../assets/logo.png';

function ToggleThemeButton() {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Button colorScheme='green' onClick={toggleColorMode} size={"sm"}>
      {colorMode === "light" ? "Farbmodus: hell" : "Farbmodus: dunkel"}
    </Button>
  );
}

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { loginData } = useLogin();
  const { colorMode } = useColorMode();

  const { isAuthenticated } = useLogin();

  return (
    <Flex bg={colorMode + ".headerColor"} p={"1em"} h={"4rem"} position={"sticky"}
      flexDirection={"row"} justifyContent={"space-between"} alignItems="center">
      <Box w={"44%"}>
        <Link to={'/'}>
          <Image src={LogoImage} alt="ESG BOT Logo" maxHeight="50px" />
        </Link>
      </Box>
      <Box ml="-12em" mt="1">
        <Breadcrumb separator={"<>"}>
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate('/')}>Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate(`/chats`)}>Chats</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => {
              navigate(`/compass`)
            }}>Kompass</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => {
              navigate(`/dvcompass`)
            }}>DV Kompass</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>
      <ToggleThemeButton />
      <Flex direction={"row"}>
        <Link to={'/login'}>
          {loginData.user ? (
            <UserAvatar
              name={loginData.user.display || loginData.user.name}
              color={loginData.user.color || "gray.500"}
              size="md"
            />
          ) : (
            <Circle mt="-1" bg="gray.500" size={'42px'} alignSelf={"end"}></Circle>
          )}
        </Link>
      </Flex>
    </Flex>
  );
};

export default Header;